import React from 'react';
import '../bio/RotaBio.css';
import logo from '../bio/assets/logo-mbs.png';
import bannerUm from '../bio/assets/banner-um.png';
import bannerDois from '../bio/assets/banner-dois.png'
import bannerTres from '../bio/assets/banner-tres.png';
import facebookIcon from '../bio/assets/icon-facebook.png';
import instagramIcon from '../bio/assets/icon-instagram.png';

const RotaBio = () => {
  return (
    <div className="link-bio-container">
      <div className="link-bio-logo-container">
        <img src={logo} alt="Logo" className="link-bio-logo" />
      </div>

      <div className="banners-route-container">
        <div className="link-bio-banners">
          <a
            href="https://loja.boasorteviajante.com.br/livro-digital-plantas-medicinais"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={bannerUm} className="link-bio-banner" />
          </a>

          <a
            href="https://loja.boasorteviajante.com.br/guia-de-viagens-mg-vol1"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={bannerDois} className="link-bio-banner" />
          </a>

          <a
            href="https://www.youtube.com/user/matheusboasorte"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={bannerTres} className="link-bio-banner" />
          </a>
        </div>

        <div className="route-section">
          <div className="route-box-list">
            <a
              href="/"
              target="_blank"
              rel="noopener noreferrer"
              className="route-box"
            >
              Website Matheus Boa Sorte
              <i className="fa-solid fa-globe route-link-icon"></i>
            </a>
            <a
              href="https://boasorteviajante.com.br/"
              target="_blank"
              rel="noopener noreferrer"
              className="route-box"
            >
              Portal Boa Sorte Viajante
              <i className="fa-solid fa-globe route-link-icon"></i>
            </a>
          </div>
        </div>
      </div>

      <div className="link-bio-social-icons">
        <a
          href="https://www.facebook.com/matheusboasorte/?locale=pt_BR"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src={facebookIcon}
            alt="Facebook"
            className="link-bio-social-icon"
          />
        </a>
        <a
          href="https://www.instagram.com/matheusboasorte/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src={instagramIcon}
            alt="Instagram"
            className="link-bio-social-icon"
          />
        </a>
      </div>
    </div>

  );
};

export default RotaBio;