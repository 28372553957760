import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { login } from './services/apiService';
import './LoginPage.css';

function LoginPage() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [isCooldown, setIsCooldown] = useState(false);
    const [remainingTime, setRemainingTime] = useState(0);
    const navigate = useNavigate();

    const MAX_ATTEMPTS = 3;
    const COOLDOWN_TIME = 2 * 60 * 1000;

    const getLoginAttempts = () => {
        const attempts = localStorage.getItem('loginAttempts');
        return attempts ? JSON.parse(attempts) : { count: 0, timestamp: 0 };
    };

    const setLoginAttempts = (count, timestamp) => {
        localStorage.setItem('loginAttempts', JSON.stringify({ count, timestamp }));
    };

    const handleLogin = async (e) => {
        e.preventDefault();

        const { count, timestamp } = getLoginAttempts();
        const currentTime = Date.now();

        if (count >= MAX_ATTEMPTS) {
            const timeElapsed = currentTime - timestamp;
            if (timeElapsed < COOLDOWN_TIME) {
                setIsCooldown(true);
                setRemainingTime(COOLDOWN_TIME - timeElapsed);
                return;
            } else {
                setLoginAttempts(0, 0);
            }
        }

        try {
            const token = await login(email, password);
            if (token) {
                setLoginAttempts(0, 0);
                navigate('/admin');
            }
        } catch (err) {
            const newCount = count + 1;
            const newTimestamp = currentTime;

            setLoginAttempts(newCount, newTimestamp);
            setError('E-mail ou senha incorretos. Tente novamente.');
        }
    };

    React.useEffect(() => {
        if (isCooldown) {
            const timer = setInterval(() => {
                const { count, timestamp } = getLoginAttempts();
                const timeElapsed = Date.now() - timestamp;
                const remaining = COOLDOWN_TIME - timeElapsed;

                if (remaining <= 0) {
                    clearInterval(timer);
                    setIsCooldown(false);
                    setRemainingTime(0);
                } else {
                    setRemainingTime(remaining);
                }
            }, 1000);

            return () => clearInterval(timer);
        }
    }, [isCooldown]);

    return (
        <div className="login-container">
            <div className="login-page">
                <h1>Login</h1>
                {error && !isCooldown && (
                    <div className="error">
                        {error}
                    </div>
                )}

                {isCooldown && (
                    <div className="cooldown-message">
                        <p>Você excedeu o número limite de tentativas.</p>
                        <p>Por favor, tente novamente em: <span>{Math.ceil(remainingTime / 1000)} segundos</span></p>
                    </div>
                )}

                <form onSubmit={handleLogin}>
                    <input
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder="Email"
                        required
                    />
                    <input
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        placeholder="Senha"
                        required
                    />
                    <button type="submit" disabled={isCooldown}>
                        Login
                    </button>
                </form>
            </div>
        </div>
    );
}

export default LoginPage;