import React from 'react'
import Nav from '../Nav'
import ContactForm from '../ContactForm'

function Contato() {
  return (
    <>
    <Nav></Nav>
    <ContactForm></ContactForm>
    </>
  )
}

export default Contato