import React, { useEffect, useState } from 'react';
import { getProducts, getPhotos } from './services/apiService';
import './ProductHighlights.css';

const ProductHighlights = () => {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchImagesAndProducts = async () => {
      try {
        setLoading(true);

        const productData = await getProducts();
        const imageData = await getPhotos();

        const productImages = productData.map(product => {
          const image = imageData.find(image => image.productId === product.id);
          return {
            ...product,
            image: image ? `data:${image.fileContent};base64,${image.fileBase64}` : null
          };
        });

        setProducts(productImages);
      } catch (error) {
        console.error('Error fetching products and images:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchImagesAndProducts();
  }, []);

  return (
    <div className="highlights-wrapper">
      <div className="highlights-container">
        <h2 className="highlights-title">
          Produtos Matheus Boa Sorte
          <span className="highlights-underline"></span>
        </h2>

        <div className="highlights-list">
          {loading ? (
            <div className="highlights-loading">
              <div className="highlights-ball-loader"></div>
            </div>
          ) : (
            products.length > 0 ? (
              products.slice(0, 3).map((product, index) => {
                if (!product.image) return null;

                const productName = product.name || "Produto sem título";
                
                const productCategory = (index === 2) ? "Livro Físico" : (product.category || "Livro Digital");

                return (
                  <div
                    key={product.id}
                    className={`highlights-card ${index === 0 ? 'highlights-large' : 'highlights-small'}`}
                  >
                    <a href="/produtos" className="highlights-card-link">
                      <img
                        src={product.image}
                        alt={`Product ${product.id}`}
                        className="highlights-card-image"
                      />
                    </a>

                    <h3 className="highlights-card-title">{productName}</h3>
                    <p className="highlights-card-category">{productCategory}</p>
                  </div>
                );
              })
            ) : (
              <div className="highlights-no-products">Nenhum produto encontrado</div>
            )
          )}
        </div>
      </div>
    </div>
  );
};

export default ProductHighlights;