import React from 'react';
import '../App.css';
import './HeroProd.css';

function HeroProd() {

  return (
    <div className='hero-prod-section'>
      <div className='hero-prod-container'>
        <div className='hero-prod-btns'>

        </div>
      </div>
    </div>
  );
}

export default HeroProd;