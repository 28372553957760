import React from "react";
import "./VideoSec.css";

const VideoSec = () => {
  const videoUrl = "https://www.youtube.com/embed/3OD5hOGmxcE?autoplay=0&mute=0&rel=0";

  return (
    <div className="video-section">
      <div className="video-content-wrapper">
        <h2 className="section-title">
          <span className="title-text">APERTE O PLAY</span>
          <div className="play-button-wrapper">
            <i className="fas fa-play play-button"></i>
          </div>
        </h2>

        <div className="video-strip">
          <div className="video-container">
            <iframe
              className="video-player"
              src={videoUrl}
              title="Video Story"
              frameBorder="0"
              allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VideoSec;