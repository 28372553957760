import React, { useState, useEffect, useRef } from 'react';
import { useInView } from 'react-intersection-observer';
import '../components/AuthoritySection.css';

const AuthoritySection = () => {
  const [instagramCount, setInstagramCount] = useState(0);
  const [youtubeCount, setYoutubeCount] = useState(0);
  const [tiktokCount, setTiktokCount] = useState(0);
  const [facebookCount, setFacebookCount] = useState(0);

  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  const countsRef = useRef({
    instagram: instagramCount,
    youtube: youtubeCount,
    tiktok: tiktokCount,
    facebook: facebookCount,
  });

  const targets = {
    instagram: 287000,
    youtube: 683000,
    tiktok: 134000,
    facebook: 116000,
  };

  const incrementStep = 1650;
  const incrementInterval = 20;

  useEffect(() => {
    if (inView) {

      const updateCounts = () => {
        const platformKeys = Object.keys(targets);

        platformKeys.forEach((platform) => {
          countsRef.current[platform] += incrementStep;
          if (countsRef.current[platform] >= targets[platform]) {
            countsRef.current[platform] = targets[platform];
          }
          switch (platform) {
            case 'instagram':
              setInstagramCount(countsRef.current.instagram);
              break;
            case 'youtube':
              setYoutubeCount(countsRef.current.youtube);
              break;
            case 'tiktok':
              setTiktokCount(countsRef.current.tiktok);
              break;
            case 'facebook':
              setFacebookCount(countsRef.current.facebook);
              break;
            default:
              break;
          }
        });
      };

      const intervalId = setInterval(updateCounts, incrementInterval);

      return () => clearInterval(intervalId);
    }
  }, [inView]);

  const formatCount = (count) => {
    if (count >= 1000) {
      return `+${(count / 1000).toFixed(0)}K`;
    }
    return `+${count.toLocaleString()}`;
  };

  return (
    <section ref={ref} className="authority-section">
      <div className="container">
        <h2>Uma <span className="highlight">audiência fiel</span> em diferentes plataformas</h2>
        <div className="numbers">
          <div className="number-item youtube-box">
            <div className="logo-container">
              <i className="fab fa-youtube social-logo"></i>
            </div>
            <div className="number-content">
              <h2>YouTube</h2>
              <span className="number">{formatCount(youtubeCount)}</span>
              <a href='https://www.youtube.com/user/matheusboasorte' target="_blank" rel="noopener noreferrer">
                <p>@boasorteviajante</p>
              </a>
            </div>
          </div>

          <div className="number-item tiktok-box">
            <div className="logo-container">
              <i className="fab fa-tiktok social-logo"></i>
            </div>
            <div className="number-content">
              <h2>TikTok</h2>
              <span className="number">{formatCount(tiktokCount)}</span>
              <a href='https://www.tiktok.com/@matheusboasorteoficial' target="_blank" rel="noopener noreferrer">
                <p>@matheusboasorteoficial</p>
              </a>
            </div>
          </div>

          <div className="number-item instagram-box">
            <div className="logo-container">
              <i className="fab fa-instagram social-logo"></i>
            </div>
            <div className="number-content">
              <h2>Instagram</h2>
              <span className="number">{formatCount(instagramCount)}</span>
              <a href='https://www.instagram.com/matheusboasorte/' target="_blank" rel="noopener noreferrer">
                <p>@matheusboasorte</p>
              </a>
            </div>
          </div>

          <div className="number-item facebook-box">
            <div className="logo-container">
              <i className="fab fa-facebook social-logo"></i>
            </div>
            <div className="number-content">
              <h2>Facebook</h2>
              <span className="number">{formatCount(facebookCount)}</span>
              <a href='https://www.facebook.com/matheusboasorte/?locale=pt_BR' target="_blank" rel="noopener noreferrer">
                <p>Matheus Boa Sorte</p>
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AuthoritySection;