import React from 'react'
import Footer from '../Footer'
import BlogComponent from '../BlogComponent'
import Nav from '../Nav'
import HeroBlog from '../HeroBlog'

function Blog() {
    return (
        <>
            <Nav></Nav>
            <HeroBlog></HeroBlog>
            <BlogComponent></BlogComponent>
            <Footer></Footer>
        </>
    )
}

export default Blog