import React from 'react';
import '../App.css';
import './Hero.css';

function HeroSection() {

  return (
    <div className='hero-section'>
      <div className='hero-container'>
        <div className='hero-btns'>

        </div>
      </div>
    </div>
  );
}

export default HeroSection;