import React from 'react'
import Footer from '../Footer';
import PhotoSec from '../PhotoSec';
import FaqSec from '../FaqSec';
import Marcas from '../Marcas';
import Nav from '../Nav';
import BioSec from '../BioSec';
import VideoSec from '../VideoSec';

function Sobre() {
    return (
        <>
            <Nav></Nav>
            <BioSec></BioSec>
            <VideoSec></VideoSec>
            <PhotoSec></PhotoSec>
            <Marcas></Marcas>
            <FaqSec></FaqSec>
            <Footer></Footer>
        </>
    )
}

export default Sobre