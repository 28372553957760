import React from 'react'
import Footer from '../Footer';
import Spectacle from '../Spectacle';
import SubSpec from '../SubSpec';
import Nav from '../Nav';

function Sobre() {
    return (
        <>
        <Nav></Nav>
            <Spectacle></Spectacle>
            <SubSpec></SubSpec>
            <Footer></Footer>
        </>
    )
}

export default Sobre