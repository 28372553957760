import React from 'react';
import { useNavigate } from 'react-router-dom';
import './NotFound.css';

function NotFound() {
    const navigate = useNavigate();

    const handleGoHome = () => {
        navigate('/');
    };

    return (
        <div className="not-found-container">
            <div className="not-found-content">
                <h1 className="not-found-title">404</h1>
                <p className="not-found-message">Ops! Não conseguimos encontrar esta página.</p>
                <button onClick={handleGoHome} className="go-home-button">
                    Voltar para a página inicial
                </button>
            </div>
        </div>
    );
}

export default NotFound;
