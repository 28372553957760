import React from "react";
import "./PhotoSec.css";

import photo1 from '../resources/imgMatheus1.webp';
import photo2 from '../resources/imgMatheus2.webp';
import photo3 from '../resources/imgEspetaculo.webp';
import photo4 from '../resources/imgMatheus4.webp';
import photo5 from '../resources/imgMatheus5.webp';
import photo6 from '../resources/imgMatheus6.webp';
import photo7 from '../resources/imgCondeuba.webp';
import photo8 from '../resources/imgMatheus.jpg';

const PhotoSec = () => {
  const photos = [
    { src: photo1, description: "Cabaceiras, Paraíba" },
    { src: photo2, description: "Cachoeira do Abade, Pirinópolis, Goiás" },
    { src: photo3, description: 'Espetáculo "Sentimento do Sertão", Salvador' },
    { src: photo4, description: "Pé de Flamboyant, Guanambi" },
    { src: photo5, description: "Indiaroba, Sergipe" },
    { src: photo6, description: "Vale do Café, Rio de Janeiro" },
    { src: photo7, description: "Ricardinho, Condeúba" },
    { src: photo8, description: "Seu Hermes, Ruy Barbosa" },
  ];

  return (
    <div className="gallery-wrapper">
      <div className="gallery-container">
        {photos.map((photo, index) => (
          <div key={index} className="gallery-item">
            <img src={photo.src} alt={`Owner ${index + 1}`} className="gallery-img" />
            <div className="overlay">
              <p className="description">{photo.description}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PhotoSec;
