import React from 'react';
import './BriefAbout.css';
import imgMatheus1 from '../resources/imgMatheusHome.webp';
import imgMatheus2 from '../resources/imgCondeuba.webp';

const BriefAbout = () => {
  return (
    <section className="about-brief-section">
      <div className="about-brief-content">
        <div className="about-brief-text">
          <h2>Quem sou eu?</h2>
          <p>
            Sou um apaixonado pelas tradições culturais do Nordeste brasileiro. Pra ser mais específico, do sertão, o qual defendo ser muito mais que um canto de Brasil que chove pouco. Pra mim, sertão é sentimento, estado de espírito, de quem carrega em si o Sorriso, a Fé, a Persistência, a Coragem e um pouquinho da malícia.          </p>
          <p>
            Sou publicitário por formação acadêmica, e pago meus boletos através da arte. Sou cantor, compositor, escritor, palestrante, educador, ator, apresentador de TV, radialista… Muita coisa né? Mas eu resumo isso tudo em somente uma: <strong>SOU CONTADOR DE HISTÓRIAS</strong>. Não importa em qual plataforma, tudo o que eu faço na minha vida, independente da atividade, é contar histórias e ensinar alguma coisa através delas.          </p>
          <a
            href="/biografia"
            className="read-more-btn-bsv"
            target=""
            rel="noopener noreferrer"
          >
            Conheça um pouco mais da minha história
          </a>
        </div>
        <div className="about-brief-images">
          <div className="about-brief-left">
            <img src={imgMatheus1} alt="Author" className="about-brief-image" />
          </div>
          <div className="about-brief-right">
            <img src={imgMatheus2} alt="Author" className="about-brief-image" />
          </div>
        </div>
      </div>
    </section>
  );
}

export default BriefAbout;