import React, { useEffect, useState } from 'react';
import './ProductShop.css';
import { getProducts, getPhotos } from './services/apiService';

function ProductShop() {
    const [products, setProducts] = useState([]);
    const [filteredProducts, setFilteredProducts] = useState([]);
    const [sortOrder, setSortOrder] = useState('recent');
    const [priceRange, setPriceRange] = useState([0, 100]);
    const [categoryFilter, setCategoryFilter] = useState('all');
    const [isLoading, setIsLoading] = useState(true);

    const [manualProductData] = useState({
        28: { category: 1, price: 47.90, link: "https://loja.boasorteviajante.com.br/livro-digital-plantas-medicinais" },
        31: { category: 4, price: 57.90, link: "https://loja.boasorteviajante.com.br/guia-de-viagens-mg-vol1" },
        32: { category: 5, price: 89.90, link: "https://loja.boasorteviajante.com.br/livro-matuto-poeta" },
        33: { category: 1, price: 44.90, link: "https://loja.boasorteviajante.com.br/livro-matuto-poeta" },
    });

    const categoryMapping = {
        1: "Livro Digital",
        2: "Mentoria",
        3: "Curso",
        4: "Guia de Viagens",
        5: "Livro Físico",
    };

    const priceValues = Object.values(manualProductData).map(product => product.price);
    const lowestPriceOnArray = Math.min(...priceValues);
    const highestPriceOnArray = Math.max(...priceValues);

    const safeLowestPrice = isNaN(lowestPriceOnArray) ? 0 : lowestPriceOnArray;
    const safeHighestPrice = isNaN(highestPriceOnArray) ? 100 : highestPriceOnArray;

    useEffect(() => {
        setPriceRange([safeLowestPrice, safeHighestPrice]);
    }, [safeLowestPrice, safeHighestPrice]);

    useEffect(() => {
        const fetchProductsAndImages = async () => {
            try {
                setIsLoading(true);

                const productData = await getProducts();

                const imageData = await getPhotos();

                const productImages = productData.map(product => {
                    const image = imageData.find(image => image.productId === product.id);

                    return {
                        ...product,
                        image: image ? `data:${image.fileContent};base64,${image.fileBase64}` : null
                    };
                });

                setProducts(productImages);
                setFilteredProducts(productImages);
            } catch (error) {
            } finally {
                setIsLoading(false);
            }
        };

        fetchProductsAndImages();
    }, []);

    useEffect(() => {
        const applyFilters = () => {
            let filtered = [...products];

            filtered = filtered.filter(product => {
                const priceToCheck = manualProductData[product.id]?.price || product.price;
                return priceToCheck >= priceRange[0] && priceToCheck <= priceRange[1];
            });

            if (categoryFilter !== 'all') {
                const categoryFilterInt = categoryFilter === 'Livros Digitais' ? 1 :
                    categoryFilter === 'Mentorias' ? 2 :
                        categoryFilter === 'Cursos' ? 3 : null;

                if (categoryFilterInt !== null) {
                    filtered = filtered.filter(product => {
                        const categoryToCheck = manualProductData[product.id]?.category || product.category;
                        return categoryToCheck === categoryFilterInt;
                    });
                }
            }

            if (sortOrder === 'lowToHigh') {
                filtered.sort((a, b) => {
                    const priceA = manualProductData[a.id]?.price || a.price;
                    const priceB = manualProductData[b.id]?.price || b.price;
                    return priceA - priceB;
                });
            } else if (sortOrder === 'highToLow') {
                filtered.sort((a, b) => {
                    const priceA = manualProductData[a.id]?.price || a.price;
                    const priceB = manualProductData[b.id]?.price || b.price;
                    return priceB - priceA;
                });
            } else if (sortOrder === 'recent') {
                filtered.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
            }

            setFilteredProducts(filtered);
        };

        applyFilters();
    }, [sortOrder, priceRange, categoryFilter, products, manualProductData]);

    const handlePriceChange = (e) => {
        const newPriceRange = [safeLowestPrice, parseFloat(e.target.value)];
        setPriceRange(newPriceRange);
    };

    const handleCategoryChange = (e) => {
        setCategoryFilter(e.target.value);
    };

    const handleSortChange = (e) => {
        setSortOrder(e.target.value);
    };

    const handlePriceRangeDisplay = () => {
        return `R$ ${priceRange[0].toFixed(2)} - R$ ${priceRange[1].toFixed(2)}`;
    };

    return (
        <div className="product-shop-container">
            <div className="shop-menu">
                <h2>Filtrar Produtos</h2>

                <div className="filter-option">
                    <label>Filtrar por preço</label>
                    <input
                        type="range"
                        min={safeLowestPrice}
                        max={safeHighestPrice}
                        value={priceRange[1]}
                        onChange={handlePriceChange}
                        step="1"
                    />
                    <span>{handlePriceRangeDisplay()}</span>
                </div>

                <div className="filter-option">
                    <label>Ordem de filtragem:</label>
                    <select
                        value={sortOrder}
                        onChange={handleSortChange}
                    >
                        <option value="recent">Mais recentes</option>
                        <option value="lowToHigh">Ordem crescente</option>
                        <option value="highToLow">Ordem decrescente</option>
                    </select>
                </div>

                <div className="filter-option">
                    <label>Categorias:</label>
                    <select
                        value={categoryFilter}
                        onChange={handleCategoryChange}
                    >
                        <option value="all">Todas</option>
                        <option value="Livros Digitais">Livros Digitais</option>
                        <option value="Cursos">Cursos</option>
                        <option value="Mentorias">Mentorias</option>
                    </select>
                </div>
            </div>

            <div className="products-container">
                {isLoading ? (
                    <div className="loading-spinner">
                        <div className="ball-prod"></div>
                    </div>
                ) : filteredProducts.length > 0 ? (
                    filteredProducts.map((product) => {
                        if (!product.image) return null;

                        const manualCategory = manualProductData[product.id]?.category || product.category;
                        const manualPrice = manualProductData[product.id]?.price || product.price;

                        const formattedPrice = manualPrice ? `R$ ${manualPrice.toFixed(2)}` : 'Preço não disponível';

                        const displayCategory = categoryMapping[manualCategory] || "Categoria desconhecida";

                        return (
                            <div key={product.id} className="product-card">
                                <img
                                    src={product.image}
                                    alt={`Product ${product.id}`}
                                    className="product-photo"
                                />
                                <div className="product-info">
                                    <h3 className="product-title">{product.name}</h3>
                                    <p className="product-category">{displayCategory}</p>
                                    <span className="product-price">{formattedPrice}</span>
                                    <div
                                        className="product-description"
                                        dangerouslySetInnerHTML={{
                                            __html: product.description || 'Descrição não disponível'
                                        }}
                                    />
                                    <a
                                        href={product.link || manualProductData[product.id]?.link || '#'}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="btn-comprar"
                                    >
                                        Comprar
                                    </a>
                                </div>
                            </div>
                        );
                    })
                ) : (
                    <p></p>
                )}
            </div>
        </div>
    );
}

export default ProductShop;