import React from 'react'
import Footer from '../Footer'
import Nav from '../Nav'
import HeroProd from '../HeroProd'
import ProductShop from '../ProductShop'

function Produtos() {
    return (
        <>
            <Nav></Nav>
            <HeroProd></HeroProd>
            <ProductShop></ProductShop>
            <Footer></Footer>
        </>
    )
}

export default Produtos