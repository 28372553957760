import React, { useState } from "react";
import "./FaqSec.css";

const FaqSec = () => {
  const [activeIndex, setActiveIndex] = useState(null);
  const [showAll, setShowAll] = useState(false);

  const questions = [
    "Qual o foco da carreira de Matheus Boa Sorte como palestrante?",
    "De onde vem a inspiração de Matheus Boa Sorte?",
    "Onde eu posso acompanhar Matheus Boa Sorte?",
    "Em qual emissora Matheus Boa Sorte trabalha hoje?",
    "Como a música entrou na vida de Matheus Boa Sorte?",
    "Qual frase motiva Matheus Boa Sorte?",
    "Qual o maior sonho de Matheus Boa Sorte?",
    "Onde mora Matheus Boa Sorte?",
    "Qual livro de cabeceira de Matheus Boa Sorte?",
    "O que define Matheus Boa Sorte?",
  ];

  const toggleAnswer = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const toggleShowAll = () => {
    setShowAll(!showAll);
  };

  return (
    <div className="faq-wrapper">
      <div className="faq-container">
        <h2>Perguntas frequentes</h2>
        <div className="faq-list">
          {questions.slice(0, showAll ? questions.length : 5).map((question, index) => (
            <div key={index} className="faq-item">
              <div
                className="faq-question"
                onClick={() => toggleAnswer(index)}
              >
                <span>{question}</span>
                <span className="arrow">
                  {activeIndex === index ? "▲" : "▼"}
                </span>
              </div>
              {activeIndex === index && (
                <div className="faq-answer">
                  <p>This is the answer to the question.</p>
                </div>
              )}
            </div>
          ))}
        </div>

        <button className="show-all-button" onClick={toggleShowAll}>
          {showAll ? "Mostrar menos..." : "Mostrar tudo..."}
        </button>
      </div>
    </div>
  );
};

export default FaqSec;