import axios from 'axios';

const API_URL = 'https://webservice.matheusboasorte.com.br';

const getAuthToken = () => {
    return localStorage.getItem('authToken');
};

const setAuthHeader = () => {
    const token = getAuthToken();
    if (token) {
        return { Authorization: `Bearer ${token}` };
    } else {
        return {};
    }
};

const addAuthHeader = (headers = {}) => {
    const authHeader = setAuthHeader();
    return {
        ...headers,
        ...authHeader,
    };
};

export const getMessages = async () => {
    try {
        const response = await axios.get(`${API_URL}/news`);
        return response.data;
    } catch (error) {
        console.error('Error fetching messages:', error);
        throw error;
    }
};

export const getProducts = async () => {
    try {
        const response = await axios.get(`${API_URL}/product/list`);
        return response.data;
    } catch (error) {
        console.error('Error fetching products:', error.response || error.message || error);
        throw error;
    }
};

export const saveMessage = async (content) => {
    try {
        const { title, content: messageContent } = content;

        const messageData = {
            title,
            content: messageContent,
        };

        const response = await axios.post(`${API_URL}/news`, messageData, {
            headers: addAuthHeader({ 'Content-Type': 'application/json' }),
        });

        return response.data;
    } catch (error) {
        console.error('Error saving message:', error);
        throw error;
    }
};

export const updateMessage = async (message) => {
    try {
        const { id, title, content, file } = message;
        const payload = { id, title, content, file };

        const response = await axios.put(`${API_URL}/news/${id}`, payload, {
            headers: addAuthHeader({ 'Content-Type': 'application/json' }),
        });

        return response.data;
    } catch (error) {
        console.error('Error updating message:', error);
        throw error;
    }
};

export const deleteMessage = async (messageId) => {
    try {
        await axios.delete(`${API_URL}/news/${messageId}`, {
            headers: addAuthHeader({ 'Content-Type': 'application/json' }),
        });
    } catch (error) {
        console.error('Error deleting message:', error);
        throw error;
    }
};

export const saveProduct = async (productData) => {
    try {
        const response = await axios.post(`${API_URL}/product/save`, productData, {
            headers: addAuthHeader(),
        });
        return response.data;
    } catch (error) {
        console.error('Error saving product:', error);
        throw error;
    }
};


export const updateProduct = async (productData, productId) => {
    try {
        const response = await axios.put(`${API_URL}/product/update/${productId}`, productData, {
            headers: addAuthHeader(),
        });
        return response.data;
    } catch (error) {
        console.error('Erro ao atualizar produto:', error);
        throw error;
    }
};


export const deleteProduct = async (productId) => {
    try {
        await axios.delete(`${API_URL}/product/delete/${productId}`, {
            headers: addAuthHeader(),
        });
    } catch (error) {
        console.error('Error deleting product:', error);
        throw error;
    }
};

export const getPhotos = async () => {
    try {
        const response = await axios.get(`${API_URL}/file`);
        return response.data;
    } catch (error) {
        console.error('Error fetching photos:', error);
        throw error;
    }
};

export const savePhoto = async (photoData, postId) => {
    try {
        const formData = new FormData();
        formData.append('file', photoData);

        const response = await axios.post(`${API_URL}/file/upload/${postId}`, formData, {
            headers: addAuthHeader({ 'Content-Type': 'multipart/form-data' }),
        });

        return response.data;
    } catch (error) {
        console.error('Error saving photo:', error);
        throw error;
    }
};

export const saveProductPhoto = async (photoData, postId) => {
    try {
        if (!postId) {
            throw new Error('Post ID is required');
        }
        const formData = new FormData();
        formData.append('file', photoData);

        const response = await axios.post(`${API_URL}/file/upload/product/${postId}`, formData, {
            headers: addAuthHeader({ 'Content-Type': 'multipart/form-data' }),
        });

        return response.data;
    } catch (error) {
        console.error('Error saving photo:', error);
        throw error;
    }
};


export const updatePhoto = async (photo) => {
    try {
        const { id, fileData } = photo;
        const formData = new FormData();
        formData.append('file', fileData);

        const response = await axios.put(`${API_URL}/file/${id}`, formData, {
            headers: addAuthHeader({ 'Content-Type': 'multipart/form-data' }),
        });

        return response.data;
    } catch (error) {
        console.error('Error updating photo:', error);
        throw error;
    }
};

export const deletePhoto = async (photoId) => {
    try {
        await axios.delete(`${API_URL}/file/${photoId}`, {
            headers: addAuthHeader(),
        });
    } catch (error) {
        console.error('Error deleting photo:', error);
        throw error;
    }
};

export const login = async (email, password) => {
    try {
        const response = await fetch(`${API_URL}/auth/login`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ email, password }),
        });

        if (response.ok) {
            const data = await response.json();
            localStorage.setItem('authToken', data.token);
            return data.token;
        } else {
            const errorData = await response.json();
            throw new Error(errorData.message || 'Invalid credentials');
        }
    } catch (err) {
        throw new Error('Login failed. Please try again later.');
    }
};

export const logout = () => {
    localStorage.removeItem('authToken');
};
