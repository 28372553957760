import React, { useState } from 'react';
import './Marcas.css';
import imgPlaceHolder1 from '../resources/marca1.png';
import imgPlaceHolder2 from '../resources/marca2.png';
import imgPlaceHolder3 from '../resources/marca3.png';
import imgPlaceHolder4 from '../resources/marca4.png';
import imgPlaceHolder5 from '../resources/marca5.png';
import imgPlaceHolder6 from '../resources/marca6.png';
import imgPlaceHolder7 from '../resources/marca7.png';
import imgPlaceHolder8 from '../resources/marca8.png';
import imgPlaceHolder9 from '../resources/marca9.png';
import imgPlaceHolder10 from '../resources/marca10.png';
import imgPlaceHolder11 from '../resources/marca11.png';
import imgPlaceHolder12 from '../resources/marca12.png';
import imgPlaceHolder13 from '../resources/marca13.png';
import imgPlaceHolder14 from '../resources/marca14.png';
import imgPlaceHolder15 from '../resources/marca15.png';
import imgPlaceHolder16 from '../resources/marca16.png';
import imgPlaceHolder17 from '../resources/marca17.png';
import imgPlaceHolder18 from '../resources/marca18.png';
import imgPlaceHolder19 from '../resources/marca19.png';
import imgPlaceHolder21 from '../resources/marca21.png';
import imgPlaceHolder22 from '../resources/marca22.png';
import imgPlaceHolder23 from '../resources/marca23.png';

function Marcas() {
    const images = [
        imgPlaceHolder1, imgPlaceHolder2, imgPlaceHolder3, imgPlaceHolder4, imgPlaceHolder5, imgPlaceHolder6,
        imgPlaceHolder7, imgPlaceHolder8, imgPlaceHolder9, imgPlaceHolder10, imgPlaceHolder11, imgPlaceHolder12,
        imgPlaceHolder13, imgPlaceHolder14, imgPlaceHolder15, imgPlaceHolder16, imgPlaceHolder17, imgPlaceHolder18,
        imgPlaceHolder19, , imgPlaceHolder21, imgPlaceHolder22, imgPlaceHolder23
    ];

    const [currentIndex, setCurrentIndex] = useState(0);
    const imagesPerPage = 4.8;
    const totalImages = images.length;
    const scrollableBlocks = Math.ceil(totalImages / imagesPerPage) - 1;

    const scroll = (direction) => {
        let newIndex = currentIndex;

        if (direction === 'left' && currentIndex > 0) {
            newIndex = currentIndex - 1;
        } else if (direction === 'right' && currentIndex < scrollableBlocks) {
            newIndex = currentIndex + 1;
        }

        setCurrentIndex(newIndex);
    };

    const isLeftArrowDisabled = currentIndex === 0;
    const isRightArrowDisabled = currentIndex === scrollableBlocks;

    return (
        <div className="brands-section">
            <h1 className="carousel-title">
                Marcas que confiaram no meu trabalho
            </h1>

            <div className="carousel-container">
                <div 
                    className={`carousel-arrow left ${isLeftArrowDisabled ? 'disabled' : ''}`} 
                    onClick={() => scroll('left')}
                >

                </div>

                <div className="brands-container">
                    <div className="brands-inner" style={{ transform: `translateX(-${currentIndex * (100 / imagesPerPage)}%)`, transition: 'transform 0.3s ease' }}>
                        {images.map((img, index) => (
                            <img src={img} alt={`Brand ${index + 1}`} className="brand-image" key={index} />
                        ))}
                    </div>
                </div>

                <div 
                    className={`carousel-arrow right ${isRightArrowDisabled ? 'disabled' : ''}`} 
                    onClick={() => scroll('right')}
                >

                </div>
            </div>
        </div>
    );
}

export default Marcas;
