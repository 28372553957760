import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Navigate } from 'react-router-dom';
import Home from './components/pages/Home.js';
import Sobre from './components/pages/Sobre.js';
import Palestras from './components/pages/Palestras.js';
import Produtos from './components/pages/Produtos.js';
import Blog from './components/pages/Blog.js';
import BlogPostPage from './components/BlogPostPage.js';
import ScrollUp from './components/ScrollUp.js';
import MessageComponent from './components/MessageComponent.js';
import LoginPage from './components/LoginPage.js';
import PrivateRoute from './components/security/PrivateRoute.js';
import Contato from './components/pages/Contato.js';
import NotFound from './components/NotFound.js';
import RotaBio from './bio/RotaBio.js';
import LivroDigital from './components/pages/LivroDigital.js';

function App() {
  return (
    <>
      <Router>
        <ScrollUp />
        <Routes>
          <Route path='/' exact element={<Home />} />
          <Route path='/biografia' element={<Sobre />} />
          <Route path='/palestras' element={<Palestras />} />
          <Route path='/produtos' element={<Produtos />} />
          <Route path='/blog' element={<Blog />} />
          <Route path='/blog/post/:newsID' element={<BlogPostPage />} />
          <Route path='/login' element={<LoginPage />} />
          <Route path='/contato' element={<Contato />} />
          <Route path='/link-bio' element={<RotaBio />} />
          {/* <Route path='/livro-digital-matuto-poeta' element={<LivroDigital />} /> */}
          <Route path="/not-found" element={<NotFound />} />
          <Route path="*" element={<Navigate to="/not-found" />} />

          <Route
            path='/admin'
            element={<PrivateRoute element={<MessageComponent />} />}
          />
        </Routes>
      </Router>
    </>
  );
}

export default App;
